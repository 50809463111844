























import {
    Component,
    Vue
} from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";

@Component({
    components: {
        MyList
    },
    mixins: [Mixin]
})
export default class RecommendList extends Vue {
    public user_id = "";
    public list: any[] = []; // 数据的列表
    public refresh = 0; // 页面允许刷新（为了list的改变）

    public search = ""; // 搜索框的值
    public search_value = ""; // 搜索的值
    clearList() {
        this.list = [];
    }
    setList(list: any[]) {
        this.list.push(...list);
    }

    // 搜索
    onSearch() {
        this.search_value = this.search;
    }

    _delete(item) {
        this.$dialog.confirm({
            title: '提示',
            message: `是否删除此业务员--${item.member_info.realname||item.member_info.nickname}`,
        }).then(() => {
            // on close
            let _this = this
            this.$api.request({
                url: 'branch/user/del-branch-user',
                data: {
                    user_id: this.user_id,
                    branch_user_id: item.branch_user_id
                },
                success(res) {
                    _this.init()
                },
            })
        }).catch(() => {

        });
        console.log(item.branch_user_id)
    }

    init() {
        this.user_id = String(this.$route.query.user_id || "");
        this.list = [];
        this.refresh++;
    }
}
